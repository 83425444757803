import { Text, type UseModalState } from "@clipboard-health/ui-react";
import { DialogContent, Stack } from "@mui/material";
import { CbhFeatureFlag } from "@src/appV2/FeatureFlags/CbhFeatureFlag";
import { useCbhFlag } from "@src/appV2/FeatureFlags/useCbhFlag";

import { BottomSheet } from "../../components/BottomSheet";
import { Button } from "../../components/Button";
import { DialogFooter } from "../../components/DialogFooter";
import { Illustration } from "../../components/Illustration";
import { usePayTransparencyFlag } from "../lib/usePayTransparencyFlag";

function StartSurveyButton({
  onClick,
  variant = "contained",
  surveyDuration = 8,
}: {
  variant?: "contained" | "outlined";
  onClick: () => void;
  surveyDuration?: number;
}) {
  return (
    <Button variant={variant} size="medium" onClick={onClick}>
      Start {surveyDuration}-minute survey
    </Button>
  );
}

function UploadOfferLetterPayStubButton({ variant }: { variant: "contained" | "outlined" }) {
  return (
    <Button variant={variant} size="medium">
      <span>Upload offer letter/pay stub</span>&nbsp;(<strong>instant</strong>)
    </Button>
  );
}

export interface UnlockHourlyPayEstimatesBottomSheetProps {
  modalState: UseModalState;
  onStartSurvey: () => void;
}

export function UnlockHourlyPayEstimatesBottomSheet(
  props: UnlockHourlyPayEstimatesBottomSheetProps
) {
  const { modalState, onStartSurvey } = props;

  const payTransparency = usePayTransparencyFlag();
  const payDataUpload = useCbhFlag(CbhFeatureFlag.PLACEMENT_PAY_RATE_EXPERIMENT_UPLOAD_PAY_DATA, {
    defaultValue: {
      placement: "OFF",
    },
  });

  if (!payTransparency.enabled) {
    return null;
  }

  function handleOnStartSurveyClick() {
    modalState.closeModal();
    onStartSurvey();
  }

  return (
    <BottomSheet
      modalState={modalState}
      footer={
        <DialogFooter
          orientation="vertical"
          onClose={() => {
            modalState.closeModal();
          }}
        >
          {payDataUpload.placement === "PRIMARY" && (
            <Stack direction="column" spacing={4}>
              <UploadOfferLetterPayStubButton variant="contained" />
              <StartSurveyButton
                variant="outlined"
                onClick={() => {
                  handleOnStartSurveyClick();
                }}
              />
            </Stack>
          )}

          {payDataUpload.placement === "SECONDARY" && (
            <Stack direction="column" spacing={4}>
              <StartSurveyButton
                onClick={() => {
                  handleOnStartSurveyClick();
                }}
              />
              <UploadOfferLetterPayStubButton variant="outlined" />
            </Stack>
          )}

          {payDataUpload.placement === "OFF" && (
            <StartSurveyButton
              surveyDuration={2}
              onClick={() => {
                handleOnStartSurveyClick();
              }}
            />
          )}
        </DialogFooter>
      }
    >
      <DialogContent sx={{ textAlign: "center", paddingTop: 12 }}>
        <Stack direction="column" spacing={8} sx={{ alignItems: "center" }}>
          <Illustration type="unlock" />
          <Text variant="h2">Unlock hourly pay estimates</Text>
          <Text variant="body1" sx={{ textWrap: "balance" }}>
            Tell others what you have been paid to see community-sourced pay estimates on all jobs
          </Text>
        </Stack>
      </DialogContent>
    </BottomSheet>
  );
}
