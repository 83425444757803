import { Text, Title } from "@clipboard-health/ui-react";
import { CardContent, Stack } from "@mui/material";

import { Button } from "../../components/Button";
import { Card } from "../../components/Card";

interface UnlockEstimatesCardProps {
  onStartSurvey: () => void;
}

export function UnlockEstimatesCard({ onStartSurvey }: UnlockEstimatesCardProps) {
  return (
    <Card
      sx={(theme) => ({
        p: 2,
        background: `linear-gradient(180deg, ${String(
          theme.palette.shift?.day.background
        )} 19.5%, ${String(theme.palette.background.tertiary)} 100%)`,
      })}
    >
      <CardContent>
        <Stack spacing={2}>
          <Title semibold variant="h5" component="h5">
            Unlock hourly pay estimates
          </Title>

          <Text variant="body1" color={(theme) => theme.palette.text.secondary}>
            Tell others what you have been paid to see community-sourced pay estimates on all jobs
          </Text>
        </Stack>
        <Button
          variant="contained"
          size="small"
          sx={{ mt: 8 }}
          onClick={() => {
            onStartSurvey();
          }}
        >
          Start 2-minute survey
        </Button>
      </CardContent>
    </Card>
  );
}
