/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/max-dependencies */
import { isDefined } from "@clipboard-health/util-ts";
import { Stack } from "@mui/material";
import { GoogleMap } from "@react-google-maps/api";
import { logEvent } from "@src/appV2/lib/analytics";
import { LA_CENTER } from "@src/appV2/lib/GoogleMaps";
import { GoogleMapCustomMarkerWrapper } from "@src/appV2/redesign/GeoLocation/GoogleMapCustomMarkerWrapper";
import { MapMarkerExpanded } from "@src/appV2/redesign/GeoLocation/MapMarkerExpanded";
import { MapMarkerLocation } from "@src/appV2/redesign/GeoLocation/MapMarkerLocation";
import {
  DEFAULT_MAP_ZOOM,
  googleMapOptions,
} from "@src/appV2/redesign/ShiftDiscovery/MapView/constants";
import { ShiftDiscoveryMapViewMapControls } from "@src/appV2/redesign/ShiftDiscovery/MapView/MapControls";
import { useLocationFilter } from "@src/appV2/redesign/ShiftDiscovery/MapView/useLocationFilter";
import { useWorkerLocations } from "@src/appV2/redesign/ShiftDiscovery/MapView/useWorkerLocations";
import { MapViewWorkplaceClusterer } from "@src/appV2/redesign/ShiftDiscovery/MapView/WorkplaceClusterer/Clusterer";
import { type Worker } from "@src/appV2/Worker/api/types";
import { USER_EVENTS } from "@src/constants";
import { useMemo, useState } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { useGetPlacementWorkplaces } from "../api/useGetPlacementWorkplaces";
import { PLACEMENT_PATHS } from "../paths";
import { type GetPlacementsForWorkplaceQuery } from "../types/fetchPlacements.schema";
import { buildPlacementsFilter } from "../utils";

interface PlacementMapViewProps {
  worker: Worker;
  filter: GetPlacementsForWorkplaceQuery["filter"];
  showApplications: boolean;
}

export function PlacementMapView(props: PlacementMapViewProps) {
  const { worker, filter, showApplications } = props;
  const history = useHistory();

  const { locationFilter, onChangeLocationFilter, onChangeLocationFilterAfterDistance } =
    useLocationFilter();

  const { workerLocation, deviceLocation, homeLocation, shouldRequestLocationPermission } =
    useWorkerLocations();

  const [map, setMap] = useState<google.maps.Map>();

  const memorizedGetParams = useMemo(() => {
    const nonEmptyFilter = Object.fromEntries(
      Object.entries(filter ?? {}).filter(([_, value]) => value)
    );
    return {
      workerId: worker.userId,
      queryParams: {
        filter: {
          ...buildPlacementsFilter({
            filter: nonEmptyFilter,
            showApplications,
            worker,
          }),
          coordinates: locationFilter,
        },
      },
    };
  }, [filter, locationFilter, showApplications, worker]);

  const { data: workplaces, isLoading: isWorkplacesLoading } = useGetPlacementWorkplaces(
    memorizedGetParams,
    { staleTime: 60 * 1000, enabled: isDefined(locationFilter) }
  );

  return (
    <Stack sx={{ position: "relative", flex: 1 }}>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100%",
        }}
        options={googleMapOptions}
        onLoad={(map) => {
          setMap(map);
          const defaultLocation = workerLocation ?? LA_CENTER;
          map.setCenter(defaultLocation);
          onChangeLocationFilter(defaultLocation);
        }}
        onUnmount={() => {
          setMap(undefined);
        }}
        onCenterChanged={() => {
          onChangeLocationFilterAfterDistance(map?.getCenter());
        }}
      >
        {workerLocation && (
          <GoogleMapCustomMarkerWrapper position={workerLocation}>
            <MapMarkerLocation />
          </GoogleMapCustomMarkerWrapper>
        )}
        {workplaces ? (
          <MapViewWorkplaceClusterer
            workplaces={workplaces.data.map((workplace) => ({
              type: workplace.type,
              id: workplace.id,
              attributes: workplace.attributes,
              shiftsCount: workplace.attributes.placementsCount,
            }))}
            map={map}
          >
            {(workplace, position) => {
              const { rating } = workplace.attributes;
              const ratingValue = rating.value ? rating.value.toFixed(1) : undefined;
              return (
                <GoogleMapCustomMarkerWrapper key={workplace.id} position={position}>
                  {workplace.shiftsCount > 0 ? (
                    <MapMarkerExpanded
                      count={workplace.shiftsCount}
                      label="job"
                      rating={ratingValue}
                      onClick={() => {
                        logEvent(USER_EVENTS.TAP_FACILITY_PIN, {
                          workplaceId: workplace.id,
                          rating: rating.value,
                        });
                        history.push(
                          generatePath(PLACEMENT_PATHS.WORKPLACE_JOBS, {
                            workplaceId: workplace.id,
                          })
                        );
                      }}
                    />
                  ) : undefined}
                </GoogleMapCustomMarkerWrapper>
              );
            }}
          </MapViewWorkplaceClusterer>
        ) : undefined}
      </GoogleMap>
      <ShiftDiscoveryMapViewMapControls
        canCenterToDevice={(!!deviceLocation && !!map) || shouldRequestLocationPermission}
        canCenterToHome={!!homeLocation && !!map}
        isLoading={isWorkplacesLoading}
        onCenterToHome={() => {
          if (map && homeLocation) {
            map.panTo(homeLocation);
            map.setZoom(DEFAULT_MAP_ZOOM);
            onChangeLocationFilter(homeLocation);
          }
        }}
        onCenterToDevice={() => {
          if (!deviceLocation && shouldRequestLocationPermission) {
            return;
          }

          if (map && deviceLocation) {
            map.panTo(deviceLocation);
            map.setZoom(DEFAULT_MAP_ZOOM);
            onChangeLocationFilter(deviceLocation);
          }
        }}
        onZoomIn={() => {
          const currentZoom = map?.getZoom();
          if (map && currentZoom) {
            map.setZoom(currentZoom + 1);
          }
        }}
        onZoomOut={() => {
          const currentZoom = map?.getZoom();
          if (map && currentZoom) {
            map.setZoom(currentZoom - 1);
          }
        }}
      />
    </Stack>
  );
}
